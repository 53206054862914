<template>
  <div class="detail-container">
    <my-nav-bar
      title="退货详情"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <div v-for="(goods, index) in list" :key="index" class="goods">
        <div>
          <van-image width="2.133rem" height="2.133rem" :src="goods.image" />
        </div>
        <div class="right">
          <div>{{ goods.name }}</div>
          <div class="size">规格：1 * {{ goods.size }}</div>
          <div class="volume">数量：{{ goods.volume }}</div>
          <div class="amount">金额：￥{{ goods.amount }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getReturnOrder } from '@/api/return_check'
import myNavBar from '../../components/my-nav-bar.vue'
export default {
  name: 'ReturnCheckDetail',
  components: { myNavBar },
  data() {
    return {
      list: null
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      getReturnOrder({ id: this.$route.query.id }).then(res => {
        this.list = res.data.goodses
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/color';
  .detail-container {
    padding: 15px 15px 0 15px;
  }
  .goods {
    display: flex;
    background-color: #fff;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
  }
  .right {
    margin-left: 10px;
  }
  .size {
    font-size: 13px;
    color: $grayTextColor;
  }
  .volume {
    font-size: 13px;
    color: $grayTextColor;
  }
  .amount {
    font-size: 13px;
    color: $amountRedColor;
  }
</style>
