var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "detail-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "退货详情",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, _vm._l(_vm.list, function (goods, index) {
    return _c('div', {
      key: index,
      staticClass: "goods"
    }, [_c('div', [_c('van-image', {
      attrs: {
        "width": "2.133rem",
        "height": "2.133rem",
        "src": goods.image
      }
    })], 1), _c('div', {
      staticClass: "right"
    }, [_c('div', [_vm._v(_vm._s(goods.name))]), _c('div', {
      staticClass: "size"
    }, [_vm._v("规格：1 * " + _vm._s(goods.size))]), _c('div', {
      staticClass: "volume"
    }, [_vm._v("数量：" + _vm._s(goods.volume))]), _c('div', {
      staticClass: "amount"
    }, [_vm._v("金额：￥" + _vm._s(goods.amount))])])]);
  }), 0)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }